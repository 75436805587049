import React from 'react';
//@ts-ignore
import styles from './TransitionComponent.module.scss?module';

const TransitionComponent = ({ children, isTransitioning }) => {
  return (
    <div
      className={`${styles.PageTransition} ${
        isTransitioning ? '' : styles.Visible
      }`}
    >
      {children}
    </div>
  );
};

export default TransitionComponent;