import { useState, useRef, useEffect } from 'react';

const useInViewArray = (
  length: number,
  parentRefs: React.MutableRefObject<Element | null>,
  childRefs: React.MutableRefObject<Element | null>[]
): {
  isElementInViewArray: boolean[];
  setInViewAtIndex: (index: number, value: boolean) => void;
} => {
  // Initialize an array of booleans with the specified length, all set to false
  const [isElementInViewArray, setIsElementInViewArray] = useState<boolean[]>(
    new Array(length).fill(false)
  );
  const observerRefs = useRef<IntersectionObserver[]>([]);

  useEffect(() => {
    // Create observers for each element in the childRefs array
    childRefs.forEach((childRef, index) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update the specific index in the isElementInViewArray
          setIsElementInViewArray((prevState) => {
            const newState = [...prevState];
            newState[index] = entry.isIntersecting;
            return newState;
          });
        },
        {
          threshold: [0.1],
        }
      );

      observerRefs.current.push(observer);

      if (childRef.current) {
        observer.observe(childRef.current);
      }
    });

    // Cleanup function to disconnect all observers
    return () => {
      observerRefs.current.forEach((observer) => observer.disconnect());
    };
  }, [parentRefs.current?.scroll]);

  // Function to manually set the value of a specific index
  const setInViewAtIndex = (index: number, value: boolean) => {
    setIsElementInViewArray((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  return { isElementInViewArray, setInViewAtIndex };
};

export default useInViewArray;
