import React, { Fragment, useEffect, useState } from 'react';

// @ts-ignore
import styles from './ProductPages.module.scss?module';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { getRequest } from '../../../../Helper/ApiClient';
import { INewComerWeekProduct } from '../ProductsSlideshow/ProductsSlideshow';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import NavigationButton from '../../Components/NavigationButton/NavigationButton';
import TransitionComponent from '../../../../Abstracts/TransitionComponent/TransitionComponent';
import { useNavigate } from 'react-router-dom';

export default function ProductPages() {
  const productsRequest = getRequest<INewComerWeekProduct[]>(
    `/api/onlineshop/newcomer-week/products`
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isLastProduct, setIsLastProduct] = useState(false);
  const [isFirstProduct, setIsFirstProduct] = useState(true);

  useEffect(() => {
    productsRequest?.data &&
      navigate(`/newcomer-week/products-2/${productsRequest?.data[0].erp_id}`);
  }, [productsRequest.data]);

  const handleTransition = () => {
    setIsTransitioning(true);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  function slideToNextProduct() {
    if (
      productsRequest.data?.length &&
      activeIndex == productsRequest.data?.length - 2
    )
      setIsLastProduct(true);
    navigate(
      `/newcomer-week/products-2/${
        productsRequest.data && productsRequest.data[activeIndex].erp_id
      }`
    );
    if (productsRequest.data && activeIndex == productsRequest.data?.length - 1)
      return;
    handleTransition();
    setActiveIndex((prevVal) => prevVal + 1);
    setIsFirstProduct(false);
  }

  function slideToPrevProduct(id: number) {
    if (activeIndex == 1) setIsFirstProduct(true);
    if (activeIndex == 0) return;
    handleTransition();
    navigate(`/newcomer-week/products-2/${id}`);
    setActiveIndex((prevVal) => {
      return prevVal - 1;
    });
    setIsLastProduct(false);
  }

  useEffect(() => {
    const rootElement = document.getElementsByTagName('html')[0];
    rootElement.style.scrollSnapType = 'y mandatory';
    rootElement.style.scrollPaddingTop = '56px';

    return () => {
      rootElement.style.scrollSnapType = '';
    };
  }, []);
  // const activeProduct = productsRequest.data?.[activeIndex];

  return (
    <>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          textAlign: 'center',
          width: '100%',
          zIndex: 10,
          // backgroundColor: 'aqua',
          // opacity: '.5',
          // padding: '10px',
        }}
        className="d-flex gap-6 justify-content-around bg-primary py-2"
      >
        <div
          style={{ opacity: isFirstProduct ? 0.5 : 1 }}
          onClick={() => {
            productsRequest.data &&
              slideToPrevProduct(
                productsRequest?.data[activeIndex == 0 ? 0 : activeIndex - 1]
                  .erp_id
              );
          }}
        >
          <NavigationButton icon={faArrowLeft} />
        </div>

        <div
          style={{ opacity: isLastProduct ? 0.5 : 1 }}
          onClick={() => {
            productsRequest.data && slideToNextProduct();
          }}
        >
          <NavigationButton icon={faArrowRight} />
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          maxWidth: '100dvw',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            transition: 'transform 750ms ease',
            overscrollBehavior: 'contain',
          }}
        >
          {productsRequest.data?.map((product, index) => {
            return (
              <Fragment key={product.erp_id}>
                <TransitionComponent isTransitioning={isTransitioning}>
                  <Container
                    fluid
                    style={{
                      maxWidth: '100dvw',
                      display: index == activeIndex ? 'block' : 'none',
                      // opacity: index === activeIndex ? '1' : '0',
                      // visibility: index === activeIndex ? 'visible' : 'hidden',
                      // transition:
                      //   'opacity 650ms ease-in-out, transform 650ms ease-in-out',
                      // transform:
                      //   index === activeIndex
                      //     ? 'translate3d(0, 0, 0)'
                      //     : 'translate3d(-10vw, 0, 0)',
                    }}
                  >
                    <Row
                      style={{
                        minHeight: '100vh',
                        scrollSnapAlign: 'start',
                        scrollSnapStop: 'always',
                      }}
                    >
                      <Col>
                        <Image
                          className={'w-100'}
                          src={product.image_transparent_url}
                        />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        minHeight: '100vh',
                        scrollSnapAlign: 'start',
                        scrollSnapStop: 'always',
                      }}
                    >
                      <Col>
                        <Image
                          className={'w-100'}
                          src={
                            'https://ams3.digitaloceanspaces.com/naskorsports01/document/images/nutritionfacts/642e10e9ddafb.jpeg'
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        minHeight: '100vh',
                        scrollSnapAlign: 'start',
                        // scrollSnapStop: 'always',
                      }}
                    >
                      <Col>
                        <p>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Aenean maximus, mauris sit amet pulvinar tempor,
                          ligula velit mollis turpis, nec fermentum nunc ex nec
                          tortor. Proin fringilla magna ipsum, at convallis enim
                          ornare in. Praesent cursus eros nec commodo
                          pellentesque. In diam diam, faucibus vel mi a, euismod
                          dignissim erat. Suspendisse mollis, felis quis
                          malesuada feugiat, eros nulla suscipit purus, at
                          feugiat quam elit sit amet dui. Vestibulum sapien
                          lorem, congue id rhoncus placerat, vehicula et orci.
                          Morbi sodales et ante eu aliquam. Vestibulum porta
                          eros in lectus fermentum, sed tincidunt justo
                          malesuada. Curabitur eget purus odio. Donec magna
                          orci, maximus quis erat quis, ultrices consectetur
                          nibh. Sed nec lorem facilisis, convallis lectus at,
                          suscipit dolor. Nunc sem libero, varius in sodales a,
                          finibus eu mi. Fusce risus ipsum, hendrerit id
                          vestibulum ac, ultrices nec risus. In elit nisi,
                          ullamcorper et egestas ut, mattis eget est. Sed
                          tincidunt, massa nec blandit tristique, dolor nulla
                          auctor leo, ac sodales mauris turpis lacinia enim.
                          Donec tempor erat sit amet sagittis feugiat. Cras non
                          euismod sem. Nullam ex lacus, pellentesque ut neque
                          tempor, sagittis malesuada orci. Suspendisse malesuada
                          risus nunc, eget egestas nulla scelerisque at. Vivamus
                          at est ut ex congue eleifend. Praesent sollicitudin
                          maximus eros, id commodo erat euismod a. Vivamus
                          pulvinar libero eu tellus gravida, ac ullamcorper
                          tortor luctus. Mauris eget fermentum nibh. Morbi nec
                          maximus nulla. Sed molestie suscipit porta. Nunc eget
                          lectus eu ipsum maximus pretium. Maecenas egestas erat
                          eu faucibus lacinia. Ut venenatis mi non sapien
                          sodales, non porttitor risus sagittis. Suspendisse
                          euismod augue id leo vulputate, eu ullamcorper tortor
                          condimentum. Phasellus lectus purus, lacinia in congue
                          rhoncus, sagittis ut ipsum. Vestibulum ante ipsum
                          primis in faucibus orci luctus et ultrices posuere
                          cubilia curae; Sed non erat vulputate, aliquet metus
                          eget, lacinia justo. Ut viverra quam a malesuada
                          tristique. Suspendisse ac massa nibh. Duis laoreet
                          aliquet enim, commodo placerat arcu hendrerit vitae.
                          Donec congue blandit nisl tempor semper. Integer
                          viverra scelerisque lorem nec eleifend. Duis ut
                          accumsan purus. Nam fringilla pretium posuere. Nam ac
                          condimentum diam, ac faucibus felis. Vivamus sed nibh
                          libero. Vestibulum nec commodo lectus. Quisque sed
                          nibh nisi. Fusce vel mi mattis lorem rutrum fermentum
                          eu sed massa.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </TransitionComponent>
              </Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
}
