import React, { Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './Footer/Footer';
import Header from './HeaderWhite/Header';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserProvider } from '../Context/UserContext';
import ServiceCenter from './ServiceCenter/ServiceCenter';
import Sidebar from './Sidebar/Sidebar';
// @ts-ignore
import style from './Router.module.scss?module';
import ScrollToTopBadge from '../Abstracts/ScrollToTopBadge/ScrollToTopBadge';
import { matchMultiplePaths } from '../Helper/matchMultiplePaths';
import VtsOrder from './VtsOrder/VtsOrder';
import PreHeader from './PreHeader/PreHeader';
import ScrollToTop from '../Helper/ScrollToTop';
import NewcomerWeek from './NewcomerWeek/NewcomerWeek';

const Vts = React.lazy(() => import('./Vts/Vts'));
const Awards = React.lazy(() => import('./Awards/Awards'));

const Complaints = React.lazy(() => import('./Complaints/Complaints'));
const Contact = React.lazy(() => import('./Contact/Contact'));
const NotFound = React.lazy(() => import('./NotFound/NotFound'));
const Account = React.lazy(() => import('./Account/Account'));
const PaymentMethods = React.lazy(
  () => import('./PaymentMethods/PaymentMethods')
);
const Style = React.lazy(() => import('./Style/Style'));
const ProductList = React.lazy(
  () => import('../Abstracts/ProductList/ProductList')
);
const LoginPage = React.lazy(() => import('./LoginPage/LoginPage'));
const Index = React.lazy(() => import('./Index/Index'));
const AboutUs = React.lazy(() => import('./AboutUs/AboutUs'));
const Faq = React.lazy(() => import('./Faq/Faq'));
const Terms = React.lazy(() => import('./Terms/Terms'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy/PrivacyPolicy'));
const Brands = React.lazy(() => import('./Brands/Brands'));
const Brand = React.lazy(() => import('./ProductList/Brand'));
const Category = React.lazy(() => import('./ProductList/Category'));
const SubCategory = React.lazy(() => import('./ProductList/SubCategory'));
const Sale = React.lazy(() => import('./ProductList/Sale'));
const Promo = React.lazy(() => import('./ProductList/Promo'));
const Potw = React.lazy(() => import('./ProductList/Potw'));
const ProductSize = React.lazy(() => import('./ProductList/ProductSize'));
const Product = React.lazy(() => import('./ProductList/Product'));
const VtsOrderService = React.lazy(
  () => import('../../js/ServiceCenter/vts/VtsOrderService/VtsOrderService')
);
const VirtualTradeShow = React.lazy(
  () => import('./VirtualTradeShow/VirtualTradeShow')
);
const Careers = React.lazy(() => import('./Careers/Careers'));
const NewProducts = React.lazy(() => import('./NewProducts/NewProducts'));
const Basket = React.lazy(() => import('./Basket/Basket'));
const OrderList = React.lazy(() => import('./Orders/OrderList/OrderList'));
const OrderDetails = React.lazy(
  () => import('./Orders/OrderDetails/OrderDetails')
);
const TopProducts = React.lazy(() => import('./TopProducts/TopProducts'));
const UnpaidInvoiceList = React.lazy(
  () => import('./UnpaidInvoiceList/UnpaidInvoiceList')
);
const Bonus = React.lazy(() => import('./Bonus/Bonus'));

export default function Router() {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const hideHeader = matchMultiplePaths([
    '/virtual-trade-show/*',
    '/vts',
    '/newcomer-week/home/*',
    '/newcomer-week/products/*',
    '/newcomer-week/products-2/*',
    '/newcomer-week/brands/*',
  ]);
  const hideFooter = matchMultiplePaths([
    '/virtual-trade-show/*',
    '/newcomer-week/home/*',
    '/newcomer-week/products/*',
    '/newcomer-week/products-2/*',
    '/newcomer-week/brands/*',
  ]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <QueryClientProvider client={new QueryClient()}>
        <UserProvider>
          {!hideHeader && (
            <Sidebar
              sidebarOpen={sidebarOpen}
              onSidebarToggle={(toggle) => setSidebarOpen(toggle)}
            />
          )}
          <div
            className={[
              'naskor-shop',
              style.Wrapper,
              sidebarOpen ? style.sidebarOpen : '',
            ].join(' ')}
          >
            <PreHeader />
            {!hideHeader && (
              <Header
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
            )}
            <div
              className={'naskor-shop-main'}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/style" element={<Style />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/brands" element={<Brands />} />
                  <Route
                    path="/vts-order-release"
                    element={<VtsOrderService />}
                  />
                  <Route
                    path="/virtual-trade-show/*"
                    element={<VirtualTradeShow />}
                  />
                  <Route path="/vts-order/*" element={<VtsOrder />} />
                  <Route path={'/account/*'} element={<Account />} />
                  <Route
                    path={'/payment-methods'}
                    element={<PaymentMethods />}
                  />
                  <Route path={'/careers/*'} element={<Careers />} />
                  <Route path="/vts" element={<Vts />} />
                  <Route path="/awards" element={<Awards />} />
                  <Route path="/complaints" element={<Complaints />} />
                  <Route path="/contact" element={<Contact />}>
                    <Route path="/contact/supplier" element={<Contact />} />
                    <Route path="/contact/customer" element={<Contact />} />
                  </Route>
                  <Route path="/service-center/*" element={<ServiceCenter />} />
                  <Route path="/product-list">
                    <Route path="" element={<ProductList />} />
                    <Route path="botm" element={<ProductList isBotm />} />
                    <Route
                      path="favourites"
                      element={<ProductList isFavorites />}
                    />
                    <Route path="new" element={<ProductList isNew />} />
                    <Route path="brand/:brand" element={<Brand />} />
                    <Route path="category/:category" element={<Category />} />
                    <Route
                      path="category/:category/:subCategory"
                      element={<SubCategory />}
                    />
                    <Route
                      path="sale/:clearance?/:clearanceGroup?"
                      element={<Sale />}
                    />
                    <Route path="promo/:brand?" element={<Promo />} />
                    <Route path="potw/:shopProduct?" element={<Potw />} />
                    <Route
                      path="productSize/:productSize"
                      element={<ProductSize />}
                    />
                    <Route path="product/:product" element={<Product />} />
                  </Route>
                  <Route path="/new" element={<NewProducts />} />
                  <Route path="/basket" element={<Basket />} />
                  <Route path="/my-orders" element={<OrderList />} />
                  <Route
                    path="/my-orders/:order_id"
                    element={<OrderDetails />}
                  />
                  <Route path="/top-products" element={<TopProducts />} />
                  <Route
                    path="/my-unpaid-invoices"
                    element={<UnpaidInvoiceList />}
                  />
                  <Route path="/bonus" element={<Bonus />} />
                  <Route path="/newcomer-week/*" element={<NewcomerWeek />} />
                  <Route path={'*'} element={<NotFound />} />
                </Routes>
              </Suspense>
            </div>
            {!hideFooter && <Footer />}
            <ScrollToTopBadge />
          </div>
        </UserProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
