import React from 'react';

// @ts-ignore
import gridWidthStyles from '../../scss/modules/bootstrap-modal-grid-width.module.scss?module';
// @ts-ignore
import styles from './Modal1.module.scss?module';
import { Modal } from 'react-bootstrap';
import cn from 'classnames';

export default function Modal1(props: {
  show;
  title?: string;
  children;
  onHide;
}) {
  return (
    <Modal
      show={props.show}
      dialogClassName={cn(
        gridWidthStyles.dialogClassName,
        styles.dialogClassName
      )}
      onHide={props.onHide}
    >
      <Modal.Header
        closeButton={true}
        style={{ borderBottom: '0 hidden' }}
        className={'pt-3 pt-sm-4 pt-lg-5 px-3 px-sm-4 px-lg-5 pb-0'}
      >
        <Modal.Title>
          <h2 className={'fw-bolder fst-italic'}>{props.title}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-3 p-sm-4 p-lg-5'}>{props.children}</Modal.Body>
    </Modal>
  );
}
