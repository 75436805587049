import React from 'react';

// @ts-ignore
import styles from './LandingPage.module.scss?module';
import { Col, Container, Image, Row } from 'react-bootstrap';
// import PlaceholderImage from '../../../Abstracts/PlaceholderImage/PlaceholderImage';
import Button from '../../../Abstracts/Button/Button';
import Countdown from '../../../Abstracts/Countdown/Countdown';

export default function LandingPage() {
  return (
    <>
      <Container className="d-flex gap-4 gap-lg-5 flex-column">
        <Row>
          <Col>
            {/* todo: der sftp imges src muss in einem var */}
            <Image
              className={'w-100 d-none d-md-block'}
              src={`https://files.naskorsports.com/shop/newcomer-week/landing-page/banner-desktop.jpg`}
            />
            <Image
              className={'w-100 d-md-none'}
              src={`https://files.naskorsports.com/shop/newcomer-week/landing-page/banner-mobile.jpg`}
            />
          </Col>
        </Row>
        <Row>
          <Col className={'text-center'}>
            <Countdown dateString={'2024-09-16T00:00:00.000+02:00'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Find Your Perfect Matches</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="m-0">
              From 16.09.24 to 22.09.24, experience the exciting world of new
              brands and innovative products. Only in our Newcomer Week, you can
              expect special offers and exclusive free samples for all
              participants. Get to know selected brands, their potential and
              their values and find your perfect match for you and your
              customers.
            </p>
            <br />
            <p className="m-0">
              Click on the button and save the event directly in your calendar.
            </p>
          </Col>
        </Row>
        <Row aria-label="spacer" />
        <Row>
          <Col className={'text-center'}>
            <a
              href={
                'https://files.naskorsports.com/shop/newcomer-week/landing-page/newcomer-week-event.ics'
              }
              download
            >
              <Button
                className={styles.AddToCalendarButton}
                style={{ width: '18rem', height: '3rem' }}
              >
                Add to calendar
              </Button>
            </a>
          </Col>
        </Row>
        <Row aria-label="spacer" />
        <Row>
          <Col xs={4}>
            <Image
              src={
                'https://files.naskorsports.com/shop/newcomer-week/landing-page/special-promotions.png'
              }
              className={'w-100'}
            />
          </Col>
          <Col xs={4}>
            <Image
              src={
                'https://files.naskorsports.com/shop/newcomer-week/landing-page/sample-box.png'
              }
              className={'w-100'}
            />
          </Col>
          <Col xs={4}>
            <Image
              src={
                'https://files.naskorsports.com/shop/newcomer-week/landing-page/amazing-brands.png'
              }
              className={'w-100'}
            />
          </Col>
        </Row>
        <Row aria-label="spacer" />
        <Row className='gap-4 gap-md-0'>
          <Col
            xs={12}
            md={6}
            className="d-flex align-items-center flex-column gap-4 justify-content-center"
          >
            <p className="m-0 text-center">
              Take the chance to present the latest trends and products to gain
              a competitive advantage. Don't miss this unique opportunity to get
              to know the highlights of the industry and benefit from unbeatable
              promotions. The offers are only valid during Newcomer Week. Save
              the date and don't miss out!
            </p>
            <div>
              <a
                href={
                  'https://files.naskorsports.com/shop/newcomer-week/landing-page/newcomer-week-event.ics'
                }
                download
              >
                <Button
                  className={styles.AddToCalendarButton}
                  style={{ width: '18rem', height: '3rem' }}
                >
                  Add to calendar
                </Button>
              </a>
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
          >
            <img
              className="w-100"
              src="https://files.naskorsports.com/shop/newcomer-week/landing-page/newcomer-week-landingpage-LP-pre-Event.jpg"
              alt="LP pre Event image"
            />
          </Col>
        </Row>
        <Row aria-label="spacer" />
      </Container>
    </>
  );
}
