import React from 'react';

// @ts-ignore
import styles from './Brands.module.scss?module';
import { Container } from 'react-bootstrap';
import BrandsSlideShow from './BrandsSlideShow/BrandsSlideShow';

export default function Brands() {
  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-around flex-grow-1"
    >
      {/* <Row aria-label="spacer" /> */}
      <BrandsSlideShow />
    </Container>
  );
}
