import React, { useEffect, useRef, useState } from 'react';

// @ts-ignore
import styles from './ProductSlides.module.scss?module';
import useInView from '../../../../../Hooks/useInView';
import { SwiperSlide } from 'swiper/react';
import ActionButtons from '../../ActionButtons/ActionButtons';
import { ViewportType } from '../../Products';

export default function ProductSlides({
  productsRequest,
  activeIndex,
  viewport,
}: {
  productsRequest: any;
  activeIndex: any;
  viewport: ViewportType;
}) {
  const [dataLength, setDataLength] = useState(0);

  const firstSectionRef = useRef<React.RefObject<Element>[]>([]);
  const secondSectionRef = useRef<React.RefObject<Element>[]>([]);
  const thirdSectionRef = useRef<React.RefObject<Element>[]>([]);
  const verticalSwiperRefs = useRef(
    Array.from({ length: 20 }).map(() => React.createRef())
  );
  const parentRef = useRef(
    Array.from({ length: 20 }).map(() => React.createRef())
  );
  const [verticalPosition, setVerticalPosition] = useState(
    Array.from({ length: 20 }).map(() => 0)
  );

  // const [activeIndex, setActiveIndex] = useState(() => {
  //   const index = productIds.indexOf(pid as string);
  //   return index !== -1 ? index : 0;
  // });

  useEffect(() => {
    if (productsRequest.data) {
      firstSectionRef.current = productsRequest.data?.map(() =>
        React.createRef<Element>()
      );
      secondSectionRef.current = productsRequest.data?.map(() =>
        React.createRef<Element>()
      );
      thirdSectionRef.current = productsRequest.data?.map(() =>
        React.createRef<Element>()
      );
      verticalSwiperRefs.current = productsRequest.data?.map(() =>
        React.createRef<Element>()
      );
      parentRef.current = productsRequest.data?.map(() =>
        React.createRef<Element>()
      );
      setVerticalPosition(productsRequest.data.map(() => 0));
      setDataLength(productsRequest.data.length);
    }
  }, [productsRequest.isSuccess]);

  const firstSectionInView = useInView(
    dataLength,
    //@ts-ignore
    verticalSwiperRefs?.current[activeIndex],
    firstSectionRef?.current
  );

  const secondSectionInView = useInView(
    dataLength,
    //@ts-ignore
    verticalSwiperRefs?.current[activeIndex],
    secondSectionRef?.current
  );

  const thirdSectionInView = useInView(
    dataLength,
    //@ts-ignore
    verticalSwiperRefs?.current[activeIndex],
    thirdSectionRef?.current
  );

  const contentStyle = {
    minHeight: viewport === 'mobile' ? '50vh' : '90vh',
    scrollSnapAlign: 'start',
    fontSize: '2rem',
    color: 'white',
  };

  const navigateToVerticalSlide = (slideId: string, index: number) => {
    const elementId = `${slideId}-${index}`;
    document.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <>
      {productsRequest.data?.map((product: any, index: number) => {
        return (
          <SwiperSlide style={{ position: 'relative' }} key={product.erp_id}>
            {/* Vertical navigation buttons */}
            {activeIndex === index && viewport === 'desktop' && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  top: '50%',
                  right: '0',
                  transform: 'translateY(-50%)',
                  display: 'flex',
                  gap: '0.5rem',
                }}
                ref={parentRef[index]}
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                    <div
                      aria-label="action-buttons"
                      className="d-flex flex-column justify-content-center gap-3 position-relative"
                      style={{ position: 'relative' }}
                    >
                      <ActionButtons
                        index={index}
                        firstSectionInView={firstSectionInView}
                        secondSectionInView={secondSectionInView}
                        thirdSectionInView={thirdSectionInView}
                        navigateToVerticalSlide={navigateToVerticalSlide}
                      />
                    </div>
                  </div>
                </div>
                <div aria-label="spacer" />
              </div>
            )}
            {/* Slide */}
            <div
              style={{
                width: '100%',
                // height: viewport === 'mobile' ? '50vh' : '90vh',
                overflow: 'auto',
                scrollSnapType: 'y mandatory',
                scrollbarWidth: 'none',
                maxHeight: '100vh',
              }}
              onScroll={(e) => {
                const { scrollTop, scrollHeight, clientHeight } =
                  e.currentTarget;
                const scrollPos = scrollTop / (scrollHeight - clientHeight);
                setVerticalPosition((prevPositions) => {
                  const newPositions = [...prevPositions];
                  newPositions[index] = scrollPos * 100;
                  return newPositions;
                });
              }}
              //@ts-ignore
              ref={verticalSwiperRefs?.current[index]}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundImage:
                    'url("https://files.naskorsports.com/shop/newcomer-week/products/newcomer-week-products-background.png")',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: `center`,
                  transform: `translateY(${-verticalPosition[index] * 2}px)`,
                  width: '100%',
                  height:
                    viewport === 'desktop' && activeIndex === index
                      ? '130%'
                      : viewport === 'mobile' && activeIndex === index
                      ? '300%'
                      : '100%',
                  zIndex: -1,
                  transition: 'height 1s ease-in-out',
                }}
              />
              <div
                //@ts-ignore
                ref={firstSectionRef?.current[index]}
                id={`first-${index}`}
                className="first"
                style={{
                  ...contentStyle,
                  backgroundImage: `url("${product.image_transparent_url}")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                  minHeight: 'calc(100vh - 56px)',
                }}
              >
                <div className="h-100 d-flex justify-content-center align-items-end">
                  <span
                    className="bg-danger"
                    style={{
                      color: '#eee',
                      position: 'absolute',
                      padding: '0.2rem 0.5rem',
                      top: '10px',
                      left: '10px',
                      borderRadius: '5px',
                      fontSize: '1.5rem',
                    }}
                  >
                    20% off
                  </span>
                  <div className={styles.ScrollDownAnimationCotainer}>
                    <div className={`${styles.ScrolldownArrows}`}>
                      <div
                        className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow1}`}
                      ></div>
                      <div
                        className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow2}`}
                      ></div>
                      <div
                        className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow3}`}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                //@ts-ignore
                ref={secondSectionRef.current[index]}
                id={`secound-${index}`}
                className="child"
                style={{
                  ...contentStyle,
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '10px',
                  minHeight: 'calc(100vh - 56px)',
                }}
              >
                <div className="d-flex h-100 justify-content-between flex-column px-2">
                  <h2 className="pt-2">FoodSpring</h2>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flex: '1',
                    }}
                  >
                    <img
                      width={'100%'}
                      src="https://ams3.digitaloceanspaces.com/naskorsports01/document/images/nutritionfacts/642e10e9ddafb.jpeg"
                    />
                  </div>
                  <p>Kleiner Text Describtion</p>
                </div>
              </div>
              <div
                //@ts-ignore
                ref={thirdSectionRef.current[index]}
                id={`third-${index}`}
                className="child"
                style={{ ...contentStyle, marginTop: '10px' }}
              >
                <p>
                  {' '}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean maximus, mauris sit amet pulvinar tempor, ligula velit
                  mollis turpis, nec fermentum nunc ex nec tortor. Proin
                  fringilla magna ipsum, at convallis enim ornare in. Praesent
                  cursus eros nec commodo pellentesque. In diam diam, faucibus
                  vel mi a, euismod dignissim erat. Suspendisse mollis, felis
                  quis malesuada feugiat, eros nulla suscipit purus, at feugiat
                  quam elit sit amet dui. Vestibulum sapien lorem, congue id
                  rhoncus placerat, vehicula et orci. Morbi sodales et ante eu
                  aliquam. Vestibulum porta eros in lectus fermentum, sed
                  tincidunt justo malesuada. Curabitur eget purus odio. Donec
                  magna orci, maximus quis erat quis, ultrices consectetur nibh.
                  Sed nec lorem facilisis, convallis lectus at, suscipit dolor.
                  Nunc sem libero, varius in sodales a, finibus eu mi. Fusce
                  risus ipsum, hendrerit id vestibulum ac, ultrices nec risus.
                  In elit nisi, ullamcorper et egestas ut, mattis eget est. Sed
                  tincidunt, massa nec blandit tristique, dolor nulla auctor
                  leo, ac sodales mauris turpis lacinia enim. Donec tempor erat
                  sit amet sagittis feugiat. Cras non euismod sem. Nullam ex
                  lacus, pellentesque ut neque tempor, sagittis malesuada orci.
                  Suspendisse malesuada risus nunc, eget egestas nulla
                  scelerisque at. Vivamus at est ut ex congue eleifend. Praesent
                  sollicitudin maximus eros, id commodo erat euismod a. Vivamus
                  pulvinar libero eu tellus gravida, ac ullamcorper tortor
                  luctus. Mauris eget fermentum nibh. Morbi nec maximus nulla.
                  Sed molestie suscipit porta. Nunc eget lectus eu ipsum maximus
                  pretium. Maecenas egestas erat eu faucibus lacinia. Ut
                  venenatis mi non sapien sodales, non porttitor risus sagittis.
                  Suspendisse euismod augue id leo vulputate, eu ullamcorper
                  tortor condimentum. Phasellus lectus purus, lacinia in congue
                  rhoncus, sagittis ut ipsum. Vestibulum ante ipsum primis in
                  faucibus orci luctus et ultrices posuere cubilia curae; Sed
                  non erat vulputate, aliquet metus eget, lacinia justo. Ut
                  viverra quam a malesuada tristique. Suspendisse ac massa nibh.
                  Duis laoreet aliquet enim, commodo placerat arcu hendrerit
                  vitae. Donec congue blandit nisl tempor semper. Integer
                  viverra scelerisque lorem nec eleifend. Duis ut accumsan
                  purus. Nam fringilla pretium posuere. Nam ac condimentum diam,
                  ac faucibus felis. Vivamus sed nibh libero. Vestibulum nec
                  commodo lectus. Quisque sed nibh nisi. Fusce vel mi mattis
                  lorem rutrum fermentum eu sed massa.
                </p>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </>
  );
}
