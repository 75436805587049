import * as React from 'react';
import { Autoplay, Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState } from 'react';
// @ts-ignore
import styles from './SwiperWrapper.module.scss?module';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

interface SwiperPropsT {
  activeIndex: number;
  isEnd: Boolean;
}
interface Props {
  slideContents?: any;
  slidesPerView?: number;
  spaceBetween?: number;
  loop?: boolean;
  centeredSlides?: boolean;
  autoplay?: any;
  pagination?: any;
  breakpoints?: any;
  swiperClassName?: any;
  forceAspectRatio?: number | { [key: string]: number };
  effect?: any;
  coverflowEffect?: any;
}

export default function SwiperWrapper({
  slideContents,
  slidesPerView,
  spaceBetween,
  loop,
  centeredSlides,
  autoplay,
  pagination,
  breakpoints,
  swiperClassName,
  forceAspectRatio,
  effect,
  coverflowEffect,
}: Props) {
  const [lastItemShow, setLastItemShow] = useState(false);
  const [firstItemShow, setFirstItemShow] = useState(true);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  /* begin of handling aspect ratio */
  const [currentAspectRatio, setCurrentAspectRatio] = useState<
    number | undefined
  >(undefined);

  const updateAspectRatio = () => {
    if (typeof forceAspectRatio === 'object') {
      const viewportWidth = window.innerWidth;
      let selectedAspectRatio;

      if (viewportWidth < 576) {
        selectedAspectRatio = forceAspectRatio.xs;
      } else if (viewportWidth >= 576 && viewportWidth < 768) {
        selectedAspectRatio = forceAspectRatio.sm || forceAspectRatio.xs;
      } else if (viewportWidth >= 768 && viewportWidth < 992) {
        selectedAspectRatio =
          forceAspectRatio.md || forceAspectRatio.sm || forceAspectRatio.xs;
      } else if (viewportWidth >= 992 && viewportWidth < 1200) {
        selectedAspectRatio =
          forceAspectRatio.lg ||
          forceAspectRatio.md ||
          forceAspectRatio.sm ||
          forceAspectRatio.xs;
      } else if (viewportWidth >= 1200 && viewportWidth < 1400) {
        selectedAspectRatio =
          forceAspectRatio.xl ||
          forceAspectRatio.lg ||
          forceAspectRatio.md ||
          forceAspectRatio.sm ||
          forceAspectRatio.xs;
      } else {
        selectedAspectRatio =
          forceAspectRatio.xxl ||
          forceAspectRatio.xl ||
          forceAspectRatio.lg ||
          forceAspectRatio.md ||
          forceAspectRatio.sm ||
          forceAspectRatio.xs;
      }

      setCurrentAspectRatio(selectedAspectRatio);
    } else if (typeof forceAspectRatio === 'number') {
      setCurrentAspectRatio(forceAspectRatio);
    } else {
      setCurrentAspectRatio(undefined);
    }
  };

  const paddingBottom = currentAspectRatio
    ? currentAspectRatio * 100
    : undefined;
  const aspectRatioBoxStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: paddingBottom ? paddingBottom + '%' : undefined,
  };
  useEffect(() => {
    updateAspectRatio();
    window.addEventListener('resize', updateAspectRatio);

    return () => {
      window.removeEventListener('resize', updateAspectRatio);
    };
  }, [forceAspectRatio]);
  /* end handling of aspect ratio */

  const handleArrowBlock = (swiperObj: SwiperPropsT) => {
    if (swiperObj.activeIndex <= 0) {
      setFirstItemShow(true);
    } else {
      setFirstItemShow(false);
    }
    if (swiperObj.isEnd) {
      setLastItemShow(true);
    } else {
      setLastItemShow(false);
    }
  };

const showLeftArrowBlock = firstItemShow && !loop ? styles.ArrowParentContainerNoItemsToShow : '';
const showRightArrowBlock = lastItemShow && !loop ? styles.ArrowParentContainerNoItemsToShow : '';
const showArrowLeftBlockOnHover = firstItemShow && !loop ? styles.ArrowSpanNoItemsToShow : '';
const showArrowRightBlockOnHover = lastItemShow && !loop ? styles.ArrowSpanNoItemsToShow : '';

  return (
    <div className={[styles.SwiperWrapper, styles.SwiperBulletBlue].join(' ')}>
      <div
        className={cn(
          styles.SwiperNavigationLeft,
          !slideContents || slideContents.length < 2 ? styles.navHidden : '',
          showLeftArrowBlock
        )}
        ref={navigationPrevRef}
      >
        <span className={`${showArrowLeftBlockOnHover} fa-stack fa-2x`}>
          <FontAwesomeIcon
            icon={faCircle}
            className={['fa-stack-2x', styles.NavIconBg].join(' ')}
          />
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={['fa-stack-1x', styles.NavIcon].join(' ')}
          />
        </span>
      </div>
      <div
        className={cn(
          styles.SwiperNavigationRight,
          !slideContents || slideContents.length < 2 ? styles.navHidden : '',
          showRightArrowBlock
        )}
        ref={navigationNextRef}
      >
        <span className={`${showArrowRightBlockOnHover} fa-stack fa-2x`}>
          <FontAwesomeIcon
            icon={faCircle}
            className={['fa-stack-2x', styles.NavIconBg].join(' ')}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            className={['fa-stack-1x', styles.NavIcon].join(' ')}
          />
        </span>
      </div>
      <Swiper
        modules={[Navigation, Autoplay, Pagination, EffectCoverflow]}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={pagination}
        breakpoints={breakpoints}
        slidesPerView={slidesPerView ?? 1}
        loop={loop}
        centeredSlides={centeredSlides}
        autoplay={autoplay}
        spaceBetween={spaceBetween ?? 10}
        className={swiperClassName}
        style={forceAspectRatio ? aspectRatioBoxStyle : {}}
        effect={effect}
        coverflowEffect={coverflowEffect}
        onSlideChange={handleArrowBlock}
      >
        {slideContents &&
          slideContents.map((slideContent, index: number) => {
            return (
              <SwiperSlide draggable={false} key={index}>
                {slideContent}
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
