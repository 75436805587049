import React from 'react';
import {
  type FontAwesomeIconProps,
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome';
import { type IconDefinition } from '@fortawesome/pro-solid-svg-icons';

//@ts-ignore
import styles from './NavigationButton.module.scss?module';

const NavigationButton = ({
  size,
  icon,
}: {
  size?: FontAwesomeIconProps['size'] | undefined;
  icon: IconDefinition;
}) => {
  return (
    <button className={styles.NavigationButton}>
      <FontAwesomeIcon
        size={size ? size : 'lg'}
        className={styles.NavigationIcons}
        icon={icon}
      />
    </button>
  );
};

export default NavigationButton;
