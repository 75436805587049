import React from 'react';
// @ts-ignore
import styles from './NewcomerWeek.module.scss?module';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import Brands from './Brands/Brands';
import Products from './Products/Products';
import Home from './Home/Home';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Favorites from './Favorites/Favorites';
import Basket from './Basket/Basket';
import ProductPages from './Products/ProductPages/ProductPages';

const Layout = ({ children }) => {
  return (
    <div className={'flex-col-grow'}>
      <Header />
      <div className={'flex-col-grow'} style={{ minHeight: '500px' }}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default function NewcomerWeek() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/home"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/brands/:bid?"
        element={
          <Layout>
            <Brands />
          </Layout>
        }
      />
      <Route
        path="/products/:pid?"
        element={
          <Layout>
            <Products />
          </Layout>
        }
      />
      <Route
        path="/products-2/:pid?"
        element={
          <Layout>
            <ProductPages />
          </Layout>
        }
      />
      <Route
        path="/favorites"
        element={
          <Layout>
            <Favorites />
          </Layout>
        }
      />
      <Route
        path="/basket"
        element={
          <Layout>
            <Basket />
          </Layout>
        }
      />
    </Routes>
  );
}
