import React from 'react';

// @ts-ignore
import styles from './Home.module.scss?module';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Link from '../../../Abstracts/Link/Link';

const IMAGE_SRC = 'https://files.naskorsports.com/shop/newcomer-week/';

export default function Home() {
  return (
    <Container className="d-flex flex-column gap-4 overflow-auto">
      <Row aria-label="spacer" />
      <Row>
        <Col>
          <h1>Newcomer week</h1>
        </Col>
      </Row>
      <Row className="flex-column flex-lg-row d-flex gap-4 gap-lg-0">
        <Col lg={6}>
          <Link to={'/newcomer-week/brands'} opacityOnHover={false}>
            <Image
              className={styles.Images}
              src={`${IMAGE_SRC}newBrandsDesktop.png`}
            />
          </Link>
        </Col>
        <Col lg={6}>
          <Link to={'/newcomer-week/products'} opacityOnHover={false}>
            <Image
              className={styles.Images}
              src={`${IMAGE_SRC}newProductsDesktop.png`}
            />
          </Link>
        </Col>
        <Col lg={6}>
          <Link to={'/newcomer-week/products-2'} opacityOnHover={false}>
            <div
              style={{ padding: '0.5rem', height: 50, width: 100 }}
              className="bg-primary text-white d-flex justify-content-center align-items-center"
            >
              Products 2
            </div>
          </Link>
        </Col>
      </Row>
      <Row aria-label="spacer" />
      <Row>
        <Col>
          <Link href={'/'}>
            <span
              style={{ backgroundColor: 'darkblue', color: 'white' }}
              className={'p-4'}
            >
              Exit event
            </span>
          </Link>
        </Col>
      </Row>
      <Row aria-label="spacer" />
    </Container>
  );
}
