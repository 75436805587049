import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';

//@ts-ignore
import styles from './BrandsSlideShow.module.scss?module';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartShopping,
  faInfo,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';

interface INewComerWeekBrands {
  id: number;
  name: string;
  erp_id: number;
  brand_name: string;
  brand_image_url: string;
  brand_video_url: string;
  content: { title: string; list_texts: string[]; description: string };
}

// fake data
const FAKE_DATA = [
  {
    id: 1,
    name: 'test 1',
    erp_id: 213123,
    brand_name: 'test 1',
    brand_image_url: 'https:www...',
    brand_video_url: 'https:www...',
    content: {
      title: 'we Prioritize:',
      list_texts: ['content text....', 'content text....', 'content text....'],
      description:
        'These principles ensure that our products are not only effective......',
    },
  },
  {
    id: 2,
    name: 'test 2',
    erp_id: 523423,
    brand_name: 'test 2',
    brand_image_url: 'https:www...',
    brand_video_url: 'https:www...',
    content: {
      title: 'we Prioritize:',
      list_texts: ['content text....', 'content text....', 'content text....'],
      description:
        'These principles ensure that our products are not only effective......',
    },
  },
  {
    id: 3,
    name: 'test 3',
    erp_id: 632423,
    brand_name: 'test 3',
    brand_image_url: 'https:www...',
    brand_video_url: 'https:www...',
    content: {
      title: 'we Prioritize:',
      list_texts: ['content text....', 'content text....', 'content text....'],
      description:
        'These principles ensure that our products are not only effective......',
    },
  },
  {
    id: 3,
    name: 'test 3',
    erp_id: 632423,
    brand_name: 'test 3',
    brand_image_url: 'https:www...',
    brand_video_url: 'https:www...',
    content: {
      title: 'we Prioritize:',
      list_texts: ['content text....', 'content text....', 'content text....'],
      description:
        'These principles ensure that our products are not only effective......',
    },
  },
  {
    id: 3,
    name: 'test 3',
    erp_id: 632423,
    brand_name: 'test 3',
    brand_image_url: 'https:www...',
    brand_video_url: 'https:www...',
    content: {
      title: 'we Prioritize:',
      list_texts: ['content text....', 'content text....', 'content text....'],
      description:
        'These principles ensure that our products are not only effective......',
    },
  },
  {
    id: 3,
    name: 'test 3',
    erp_id: 632423,
    brand_name: 'test 3',
    brand_image_url: 'https:www...',
    brand_video_url: 'https:www...',
    content: {
      title: 'we Prioritize:',
      list_texts: ['content text....', 'content text....', 'content text....'],
      description:
        'These principles ensure that our products are not only effective......',
    },
  },
];

const SwiperSlides = ({
  brandsData,
  activeSlide,
}: {
  brandsData: INewComerWeekBrands[];
  activeSlide: number;
}) => {
  const videoRefs = useRef<React.RefObject<HTMLVideoElement>[]>(
    brandsData.map(() => React.createRef<HTMLVideoElement>())
  );

  useEffect(() => {
    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    });

    if (
      videoRefs.current[activeSlide] &&
      videoRefs.current[activeSlide].current
    ) {
      //@ts-ignore
      videoRefs.current[activeSlide].current.play();
    }
  }, [activeSlide]);

  return brandsData.map((brandData, index) => {
    const isActiveSlide = activeSlide === index;
    const isPrevActiveSlide = activeSlide === index - 1;
    const isNextActiveSlide = activeSlide === index + 1;

    return (
      <SwiperSlide
        style={{
          width: '65%',
          height: '100%',
          opacity: isNextActiveSlide || isPrevActiveSlide ? '0' : '1',
          transition: 'all 300ms ease-in-out',
        }}
        className={styles.SwiperSlide}
        key={index}
      >
        <Container className="h-100" style={{ position: 'relative' }}>
          <Row className="h-100">
            <Col
              // md={5}
              xxl={5}
              className="overflow-hidden position-relative"
              style={{ borderRadius: '25px', border: '5px solid black', minHeight: '25rem' }}
            >
              <video
                src="https://files.myfit24.de/videos/myfit24-2024-720p.mp4"
                ref={videoRefs.current[index]}
                autoPlay={isActiveSlide}
                muted
                loop
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </Col>
            <div className="p-0" style={{ width: '0.24px' }}></div>
            <Col
              className="d-flex flex-column justify-content-between"
              style={{
                background: '#fff',
                border: '5px solid black',
                borderRadius: '25px',
                gap: 'auto',
              }}
            >
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex justify-content-end">
                  <button className={styles.CloseBtn}>
                    <FontAwesomeIcon size="2xl" icon={faXmark} />
                  </button>
                </div>
                <h4 className="m-0">We Prioritize:</h4>
                <div
                  style={{
                    direction: 'rtl',
                    width: '100%',
                    // height: 'min-content',
                    height: '21.8rem',
                    overflow: 'auto',
                  }}
                >
                  <ul
                    className={`${styles.CustomScroll} ${styles.Ul} pt-2`}
                    style={{ overflow: 'auto', maxHeight: '93%' }}
                  >
                    <li>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Corrupti, facilis?
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Esse et accusantium eaque doloribus ex.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Esse deserunt laborum incidunt ratione cumque mollitia
                      dolorum perspiciatis deleniti. Eaque, doloremque?
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Molestiae quam accusantium inventore, ipsa reiciendis
                      necessitatibus dolore corrupti unde minima, rerum nostrum
                      ex quas amet, quia quibusdam?
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quam doloremque totam ullam magni dolor voluptas illum
                      nostrum! Recusandae.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quam doloremque totam ullam magni dolor voluptas illum
                      nostrum! Recusandae.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quam doloremque totam ullam magni dolor voluptas illum
                      nostrum! Recusandae.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quam doloremque totam ullam magni dolor voluptas illum
                      nostrum! Recusandae.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quam doloremque totam ullam magni dolor voluptas illum
                      nostrum! Recusandae.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quam doloremque totam ullam magni dolor voluptas illum
                      nostrum! Recusandae.
                    </li>
                  </ul>
                </div>
              </div>
              <p
                className={`${styles.CustomScroll} m-0 px-2`}
                style={{ overflow: 'auto', maxHeight: '15%' }}
              >
                <strong className="d-flex">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quos, esse maiores tempora ut explicabo odio nulla mollitia
                  Quos, esse maiores tempora ut explicabo odio nulla mollitia
                  Quos, esse maiores tempora ut explicabo odio nulla mollitia
                  Quos, esse maiores tempora ut explicabo odio nulla mollitia
                </strong>
              </p>
              <div
                className={`${styles.ShopNowBtnContainer} d-flex justify-content-center align-items-center`}
              >
                <button className={styles.ShopNowBtn}>Shop Now</button>
              </div>
              <div aria-label="spacer" />
            </Col>
          </Row>
          <div
            className={`${styles.NextPrevImgesContainer} d-none d-xxl-flex`}
            style={{
              transform: 'translate(-104%,10%)',
              left: '0',
              backgroundImage:
                'url("//files.naskorsports.com/image/banner/slider/2024/8/big_banner-4887.jpeg")',
            }}
          />
          <div
            className={`${styles.NextPrevImgesContainer} d-none d-xxl-flex`}
            style={{
              right: '0',
              transform: 'translate(104%,10%)',
              backgroundImage:
                'url("https://ams3.digitaloceanspaces.com/naskorsports01/document/images/flavours/512/f81555_paic14.webp")',
            }}
          />
        </Container>
      </SwiperSlide>
    );
  });
};

const BrandsSlideShow = () => {
  const [isMobile, setIsMobile] = useState<'mobile' | 'desktop'>('mobile');
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  function checkUserScreen() {
    setIsMobile(window.innerWidth < 500 ? 'mobile' : 'desktop');
  }

  useEffect(() => {
    checkUserScreen();
    window.addEventListener('resize', checkUserScreen);
    return () => window.removeEventListener('resize', checkUserScreen);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <div style={{ height: '75vh', minHeight: '75vh', maxHeight: '45em' }}>
            {/* {isMobile == 'mobile' && (
              <>
                <Swiper
                  ref={swiperRef}
                  // className={styles.MobileSwiper}
                  modules={[Navigation, Pagination]}
                  speed={900}
                  grabCursor
                  centeredSlides
                  onSlideChange={(swiper) => {
                    const newIndex = swiper.activeIndex;
                    setActiveIndex(newIndex);
                  }}
                  initialSlide={activeIndex}
                  breakpoints={{
                    0: { slidesPerView: 1.5 },
                    992: { slidesPerView: 2 },
                  }}
                  style={{ height: '100%' }}
                >
                  {SwiperSlides({
                    brandsData: FAKE_DATA,
                    activeSlide: activeIndex,
                  })}
                </Swiper>
              </>
            )} */}
            {isMobile && (
              <>
                <Swiper
                  ref={swiperRef}
                  className={styles.SwiperDesk}
                  modules={[Navigation, Pagination]}
                  speed={900}
                  effect={'coverflow'}
                  grabCursor
                  centeredSlides
                  navigation={{
                    nextEl: `.${styles.CustomSwiperButtonNext}`,
                    prevEl: `.${styles.CustomSwiperButtonPrev}`,
                  }}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 3,
                    // slideShadows: true,
                  }}
                  onSlideChange={(swiper) => {
                    const newIndex = swiper.activeIndex;
                    setActiveIndex(newIndex);
                  }}
                  initialSlide={activeIndex}
                  slidesPerView={'auto'}
                  breakpoints={{
                    0: { slidesPerView: 1 },
                    1200: { slidesPerView: 1.6 },
                  }}
                  style={{ overflowX: 'hidden', overflowY: 'auto' }}
                >
                  <div
                    className={`swiper-button-next ${styles.CustomSwiperButtonNext}`}
                  ></div>
                  <div
                    className={`swiper-button-prev ${styles.CustomSwiperButtonPrev}`}
                  ></div>
                  {SwiperSlides({
                    brandsData: FAKE_DATA,
                    activeSlide: activeIndex,
                  })}
                </Swiper>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mx-auto">
        <Col className="d-flex gap-4">
          <button
            className={`${styles.ActionButton} ${styles.CartShoppingBtn}`}
          >
            <FontAwesomeIcon size="2xl" icon={faCartShopping} />
          </button>
          <button className={`${styles.ActionButton} ${styles.InfoBtn}`}>
            <FontAwesomeIcon size="3x" icon={faInfo} />
          </button>
        </Col>
      </Row>
    </>
  );
};

export default BrandsSlideShow;
