import React from 'react';

// @ts-ignore
import styles from './Header.module.scss?module';
import { Col, Container, Row } from 'react-bootstrap';
import Link from '../../../Abstracts/Link/Link';
import {
  faHouse,
  faBagShopping,
  faHeart,
} from '@fortawesome/pro-solid-svg-icons';
import NavigationButton from '../Components/NavigationButton/NavigationButton';

export default function Header() {
  return (
    <>
      <div style={{ color: 'aqua', padding: '10px' }}>Save space header</div>
      <Container
        fluid
        className={styles.Header}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          opacity: '.5',
        }}
      >
        <Row
          className={'py-2'}
          style={{ maxWidth: '100vw', overflowX: 'hidden' }}
        >
          <Col xs={3}>
            <Link
              to={'/newcomer-week/home'}
              inheritColor
              className={`${styles.Nav} p-0`}
            >
              <NavigationButton icon={faHouse} />
            </Link>
          </Col>
          <Col xs={6} className={'text-center fw-bold my-auto'}>
            Newcomer Week
          </Col>
          <Col xs={3} className={'text-end d-flex gap-2 justify-content-end'}>
            <Link
              to={'/newcomer-week/favorites'}
              inheritColor
              // className={styles.Nav}
            >
              <NavigationButton icon={faHeart} />
            </Link>
            <Link
              to={'/newcomer-week/basket'}
              inheritColor
              // className={styles.Nav}
            >
              <NavigationButton icon={faBagShopping} />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}
