import React, { useState } from "react";
import { getRequest } from "../../../Helper/ApiClient";

import { MenuKey } from "./Menu";
import MenuItem from "./MenuItem/MenuItem";

interface IProps {
  active: string | null;
  setActive: React.Dispatch<React.SetStateAction<string | null>>;
  closeSidebar: () => void;
}

const Sale: React.FC<IProps> = ({ active, setActive, closeSidebar }) => {
  const [group, setGroup] = useState<any>(null);
  const [selectedSale, setSelectedSale] = useState<any>(null);
  const { data } = getRequest(
    "/api/onlineshop/promotion/active",
    "/api/onlineshop/promotion/active"
  );

  return (
    <>
      {!active && (
        <MenuItem
          text="Sale %"
          icon="right"
          onClick={() => {
            setActive(MenuKey.sale);
          }}
        />
      )}

      {active === MenuKey.sale && (
        <>
          {!group ? (
            <>
              <MenuItem
                className="fw-bold"
                text="Sale %"
                icon="left"
                onClick={() => setActive(null)}
              />
              <MenuItem text="Brand of the Month" link="product-list/botm" />
              <MenuItem text="Product of the week" link="product-list/potw" />
              <MenuItem
                text="Monthly promotions"
                icon="right"
                onClick={() =>
                  setGroup({ type: "promo", name: "Monthly promotions" })
                }
              />

              <MenuItem
                text="Clearance Deals"
                icon="right"
                onClick={() =>
                  setGroup({ type: "sale", name: "Clearance Deals" })
                }
              />
            </>
          ) : (
            <>
              <MenuItem
                className="fw-bold"
                text={group.name}
                icon="left"
                onClick={() => {
                  if (selectedSale) {
                    setGroup({
                      type: "sale",
                      name: "Clearance Deals",
                    });
                    setSelectedSale(null);
                  } else {
                    setGroup(null);
                  }
                }}
              />

              {group?.type === "promo" && (
                <>
                  <MenuItem text="» View all" link="product-list/promo" />
                  {data?.data?.promo?.map(({ name, id }, key) => (
                    <MenuItem
                      key={key}
                      text={name}
                      link={`product-list/promo/${id}`}
                      onClick={closeSidebar}
                    />
                  ))}
                </>
              )}
              {group?.type === "sale" &&
                !selectedSale &&
                data?.data?.sale &&
                Object.keys(data.data.sale).map((sale, key) => (
                  <MenuItem
                    key={key}
                    text={`${sale}% Discount`}
                    icon={
                      data.data.sale[sale]?.length &&
                      data.data.sale[sale]?.length > 0
                        ? "right"
                        : undefined
                    }
                    link={
                      data.data.sale[sale]?.length &&
                      data.data.sale[sale]?.length > 0
                        ? undefined
                        : `product-list/sale/${sale}`
                    }
                    onClick={() => {
                      setGroup({ type: null, name: `${sale}% Discount` });
                      setSelectedSale(sale);
                    }}
                  />
                ))}

              {selectedSale && data?.data?.sale && (
                <>
                  <MenuItem
                    text="» View all"
                    link={`product-list/sale/${selectedSale}`}
                  />
                  {data.data.sale[selectedSale]?.map(({ id, name }, key) => (
                    <MenuItem
                      key={key}
                      text={name}
                      link={`product-list/sale/${selectedSale}/${id}`}
                      onClick={closeSidebar}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Sale;
