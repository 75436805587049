import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/effect-cards';
// @ts-ignore
import styles from './Products.module.scss?module';
import ProductsSlideshow from './ProductsSlideshow/ProductsSlideshow';
import ProductPages from './ProductPages/ProductPages';

export type ViewportType = 'mobile' | 'desktop' | '';

export default function Products() {
  const [viewport, setViewport] = useState<ViewportType>('');

  function checkUserScreen() {
    setViewport(window.innerWidth < 500 ? 'mobile' : 'desktop');
  }

  useEffect(() => {
    checkUserScreen();
    window.addEventListener('resize', checkUserScreen);
    return () => window.removeEventListener('resize', checkUserScreen);
  }, []);

  return (
    <>
      {/* {viewport == 'mobile' && <ProductPages />} */}
      {viewport == 'mobile' && (
        <Container className="flex-col-grow">
          <Row className="flex-col-grow">
            <Col>
              <ProductsSlideshow viewport={viewport} />
            </Col>
          </Row>
        </Container>
      )}
      {viewport == 'desktop' && (
        <Container className="flex-col-grow justify-content-between">
          <Row aria-label="spacer" />
          <Row>
            <Col>
              <ProductsSlideshow viewport={viewport} />
            </Col>
          </Row>
          <Row aria-label="spacer" />
        </Container>
      )}
    </>
  );
}
