import React from 'react';
import {
  // faHeart,
  faCartShopping,
  faCircleInfo,
  faHouse,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//@ts-ignore
import styles from './ActionButtons.module.scss?module';

const ActionButtons = ({
  navigateToVerticalSlide,
  // setVerticalActiveSection,
  index,
  firstSectionInView,
  secondSectionInView,
  thirdSectionInView,
}) => {
  function handleNavigateVerticalSection(event) {
    const sectionName = event.currentTarget.name;
    navigateToVerticalSlide(sectionName, index);
  }
  // console.log(secondSectionInView, 'sec');

  return (
    <>
      <button
        name="first"
        onClick={handleNavigateVerticalSection}
        className={`${styles.ActionButtons}  
        ${firstSectionInView.isElementInViewArray[index] && styles.Active} ${
          !secondSectionInView.isElementInViewArray[index] &&
          !thirdSectionInView.isElementInViewArray[index] &&
          styles.Active
        }  bg-primary`}
      >
        <FontAwesomeIcon size="xl" icon={faHouse} />
      </button>
      <button
        name="secound"
        onClick={handleNavigateVerticalSection}
        className={`${styles.ActionButtons} ${
          secondSectionInView.isElementInViewArray[index] && styles.Active
        }  bg-primary`}
      >
        <FontAwesomeIcon size="xl" icon={faCircleInfo} />
      </button>
      <button
        name="third"
        onClick={handleNavigateVerticalSection}
        className={`${styles.ActionButtons} ${
          thirdSectionInView.isElementInViewArray[index] && styles.Active
        } bg-primary`}
      >
        <FontAwesomeIcon size="xl" icon={faCartShopping} />
      </button>
    </>
  );
};

export default ActionButtons;
