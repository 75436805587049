import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  EffectCards,
  EffectCoverflow,
  Navigation,
  Pagination,
} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// @ts-ignore
import styles from './ProductsSlideshow.module.scss?module';
import { getRequest } from '../../../../Helper/ApiClient';
import { ViewportType } from '../Products';
import ProductSlides from './ProductSlides/ProductSlides';

export interface INewComerWeekProduct {
  id: number;
  name: string;
  erp_id: number;
  brand_name: string;
  image_transparent_url: string;
}

interface ProductsSlideshowProps {
  viewport: ViewportType;
}
export default function ProductsSlideshow({
  viewport,
}: ProductsSlideshowProps) {
  const productsRequest = getRequest<INewComerWeekProduct[]>(
    `/api/onlineshop/newcomer-week/products`
  );

  const { pid } = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const refSwiper = useRef(null);

  useEffect(() => {
    if (!pid && productsRequest.isSuccess) {
      navigate(
        `/newcomer-week/products/${
          productsRequest.data && productsRequest.data[activeIndex].erp_id
        }`
      );
    }
  }, [productsRequest.isSuccess]);

  const commonSwiperProps = {
    ref: refSwiper,
    speed: 900,
    grabCursor: true,
    centeredSlides: true,
    onSlideChange: (swiper: any) => {
      const newIndex = swiper.activeIndex;
      setActiveIndex(newIndex);
      navigate(
        `/newcomer-week/products/${
          productsRequest.data && productsRequest.data[newIndex].erp_id
        }`
      );
    },
    initialSlide: activeIndex,
    breakpoints: {
      0: { slidesPerView: 1 },
      992: { slidesPerView: 2 },
    },
    style: { height: '100%' },
  };

  return (
    <>
      {viewport == 'mobile' && (
        <>
          <Swiper
            spaceBetween={10}
            {...commonSwiperProps}
            // effect={'coverflow'}
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: `.${styles.CustomSwiperButtonNext}`,
              prevEl: `.${styles.CustomSwiperButtonPrev}`,
            }}
          >
            <div
              style={{ right: '5%' }}
              className={`d-flex d-lg-none swiper-button-next ${styles.CustomSwiperButtonNext}`}
            ></div>
            <div
              style={{ left: '5%' }}
              className={`d-flex d-lg-none swiper-button-prev ${styles.CustomSwiperButtonPrev}`}
            ></div>
            {ProductSlides({
              productsRequest: productsRequest,
              activeIndex: activeIndex,
              viewport,
            })}
          </Swiper>
        </>
      )}
      {viewport == 'desktop' && (
        <>
          <Swiper
            {...commonSwiperProps}
            modules={[Navigation, Pagination, EffectCoverflow]}
            effect={'coverflow'}
            navigation={{
              nextEl: `.${styles.CustomSwiperButtonNext}`,
              prevEl: `.${styles.CustomSwiperButtonPrev}`,
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 3,
              slideShadows: true,
            }}
          >
            <div
              className={`d-none d-lg-flex swiper-button-next ${styles.CustomSwiperButtonNext}`}
            ></div>
            <div
              className={`d-none d-lg-flex swiper-button-prev ${styles.CustomSwiperButtonPrev}`}
            ></div>
            {ProductSlides({
              productsRequest: productsRequest,
              activeIndex: activeIndex,
              viewport,
            })}
          </Swiper>
        </>
      )}
    </>
  );
}
