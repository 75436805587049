import React from "react";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

const getRequest = <TResult = any, TError = any>(
  path: string,
  name?: string,
  options?: UseQueryOptions<TResult, TError>,
  axiosReqConfig?: AxiosRequestConfig
): UseQueryResult<TResult, TError> => {
  return useQuery<TResult, TError>(
    name ?? path,
    async () => {
      try {
        const config = axiosReqConfig
          ? {
              ...axiosReqConfig,
              headers: {
                Accept: "application/json",
                "X-Requested-With": "XMLHttpRequest",
                ...axiosReqConfig.headers,
              },
            }
          : {
              headers: {
                Accept: "application/json",
                "X-Requested-With": "XMLHttpRequest",
              },
            };
        const response = await axios.get<TResult>(path, config);

        return response.data;
      } catch ({ response }) {
        throw new Error(response.statusText);
      }
    },
    { ...options, refetchOnWindowFocus: false }
  );
};

const postRequest = <TResult>(path: string) =>
  useMutation<any, Error, BodyInit>((body: BodyInit) => {
    return axios
      .post<any>(path, body, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .catch((responseObj) => {
        throw new Error(responseObj.response.statusText);
      })
      .then(({ data }) => {
        if(data?.error && data?.error.length > 0) {
          throw new Error(data.error);
        }
        return data;
      })
    ;
  });

export { getRequest, postRequest };
